import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";


import Layout from '../components/layout/Layout';
import './pages-css/index.css';




const index = () => {

  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Learning Curve Brampton</title>
          <meta name="description" content="Learning Curve Brampton is a tutoring service designed to help high school students succeed in a multitude of subjects such as French, English, Physics, and Math."></meta>
          <meta name="keywords" content="Learning curve, learning curve Brampton, online tutoring, Brampton tutor, Brampton tutoring, Brampton tutoring service, online learning Brampton, Brampton online tutoring, high school french tutoring, highschool french tutoring, high school physics tutoring, highschool physics tutoring, high school math tutoring, math tutoring Brampton, physics tutoring, physics tutoring Brampton, french tutoring, french tutoring Brampton, English tutoring, English tutoring Brampton, tutor brampton, brampton tutoring, tution brampton, brampton tution"></meta>
      </Helmet>
      <Fragment>
        <div className="home-page">
          <div className="hero-section">
            <div className="contain">
              <div className="container-fluid">
                <div>
                  <h1 className="text-white hero-text" >Think Different,</h1>
                  <br/>
                  <h1 className="text-white hero-text" >Think Learning Curve</h1>
                  <br/>
                  <br/>
                  <div className="hero-button" >
                    <h2 className="text-white title-slim hero-text-sub mr-3 mb-3">Start learning today!</h2>
                    <Link className='btn btn-lg bg-yellow' to='/contact' >Contact Us!</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contain">
            <div className="container-fluid">

              <div className="home-card-container">
                <div className="home-card shadow">
                  <h2 className="text-white" >Math</h2>
                </div>
                <div className="home-card shadow">
                  <h2 className="text-white" >English</h2>
                </div>
                <div className="home-card shadow">
                  <h2 className="text-white" >Physics</h2>
                </div>
                <div className="home-card shadow">
                  <h2 className="text-white" >French</h2>
                </div>
              </div>

              <div style={{ marginBottom: '150px' }} className="text-center">
                <h2 className="text-slim home-title mb-4">Welcome to <span className="home-highlight">Learning Curve</span></h2>
                <p className="text-slim home-para mb-3">We are an online tutoring service whose goal is to aid students in their academic journey.</p>
                <p className="text-slim home-para">At Learning Curve, we strive to ensure the success of our students by providing all necessary teaching aids and developing fundamentals</p>
              </div>

              

            </div>
          </div>

          <div className="home-img-2">
            <div className="contain">
              <div className="container-fluid">
                <h2 className="text-white mb-5">Learn more about the help we can provide!</h2>
                <Link className='btn btn-lg bg-yellow' to='/services' >Services</Link>
              </div>
            </div>
          </div>

          <div className="contain">
            <div className="container-fluid">
              
              <div className="info-cards">
                <div className="info-card shadow">
                  <i aria-hidden className="fas fa-brain card-icon"></i>
                  <h4>One on One Sessions</h4>
                  <p>We believe that every student is unique and will improve the fastest when given the proper attention.</p>
                </div>
                <div className="info-card shadow">
                  <i aria-hidden className="fas fa-tags card-icon"></i>
                  <h4>Exceptional Prices</h4>
                  <p>We strive to offer exceptional service at affordable pricing to help students in need.</p>
                </div>
                <div className="info-card shadow">
                  <i aria-hidden className="fas fa-clipboard card-icon"></i>
                  <h4>Personalized lesson plans</h4>
                  <p>Our instructors help our students succeed by creating learning plans to suit their individual needs.</p>
                </div>
              </div>

              <div style={{ marginBottom: '150px' }}>
                <h2 className="text-slim home-title mb-4 align-center">More about Learning Curve:</h2>
                <div className='flex' >
                  <Link className='btn btn-yellow btn-flex' to='/contact'>Get in touch</Link>
                  <Link className='btn btn-clean btn-flex' to='/about'>More about us</Link>
                </div>
              </div>

            </div>
          </div>

        </div>
      </Fragment>
    </Layout>
    
  )
}

export default index;